import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import productsData from "../../../JSON/Products.json";
const responsive = {
  largeDesktop: {
    breakpoint: { max: 3000, min: 1921 },
    items: 6,
    slidesToSlide: 1,
  },
  inBetween: {
    breakpoint: { max: 1920, min: 1560 },
    items: 4,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1559, min: 1440 },
    items: 4,
    slidesToSlide: 1,
  },
  laptop: {
    breakpoint: { max: 1439, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tabletLandscape: {
    breakpoint: { max: 1023, min: 768 },
    items: 2,
    slidesToSlide: 1,
  },
  tabletPortrait: {
    breakpoint: { max: 767, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 463, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};
const BestSellers = () => {
  return (
    <div className="xl:px-24 xl:py-16 lg:px-16 lg:py-10 md:p-12 p-5 w-full">
      <div className="w-full flex justify-center flex-col items-center">
        <div className="max-w-screen-2xl w-full">
          <h2
            className="w-fit items-center xl:text-5xl lg:text-4xl md:text-3xl text-2xl  textblue font-bold wow fadeInLeftBig "
            data-wow-delay=".1s"
            data-wow-duration="2s"
          >
            Best Sellers
          </h2>
          <hr
            className="bgblue h-0.5 my-2 w-72 wow fadeInLeft"
            data-wow-delay=".1s"
            data-wow-duration="2s"
          />
          <Carousel
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={true}
            partialVisible={true}
            dotListClass="custom-dot-list-style"
            customTransition="transform 300ms ease-in-out"
            customTransitionLeave="transform 300ms ease-in-out"
            arrows={false}
            renderButtonGroupOutside={false}
          >
            {productsData.products.map((item) => (
              <div className="h-80 w-full m-4" key={item.id}>
                <div className="h-60   flex flex-col items-center justify-center 4xl:w-56 xl:w-72 md:w-60 w-10/12  customshadowcard">
                  <img
                    className="h-36   items-center"
                    src={item.image}
                    alt={item.title}
                  />
                </div>
                <h2 className="font-bold my-2 uppercase text-lg ">
                  {item.title}
                </h2>
                <div className="flex w-9/12  justify-between">
                  <div>
                    <p className="uppercase text-[0.7rem] font-medium text-gray-500">
                      Catergory
                    </p>
                    <p className="uppercase text-xs font-bold">
                      {item.category}
                    </p>
                  </div>
                  <div className="flex items-center gap-1">
                    <p className="uppercase text-xs font-bold">PRICE</p>
                    <p className="uppercase text-base font-bold">
                      ${item.PRICE}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default BestSellers;
