import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import logo from "../../Images/logo.png";
import logo1 from "../../Images/logo1.png";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setHovered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <header
        className={`sticky bg-gradient-to-r from-slate-950 to-[#144784] backdrop-blur-md z-20 ${
          isScrolled ? "sticky top-0 w-full  " : ""
        }`}
        style={{ opacity: isScrolled ? 1 : 1 }}
      >
        <div className="">
          <div className="my-auto mx-auto">
            <div className="flex p-2 justify-between	">
              {/* ----------logo-------- */}
              <div
                className="xl:ml-10 xl:w-40 lg:ml-8 md:ml-6 ml-4 lg:w-36 md:w-32 w-28"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                {isHovered ? (
                  <img src={logo1} alt="CCSElogo1" />
                ) : (
                  <img src={logo} alt="CCSElogo" />
                )}
              </div>
              {/* -------------menu item-------- */}
              <div className="my-auto mx-auto hidden lg:flex">
                {/* Desktop menu */}
                <ul className="flex space-x-8">
                  <li>
                    <Link
                      to="/"
                      className="text-white text-sm border-white hover:border-b-4 py-2"
                      onClick={handleScrollToTop}
                    >
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/Products"}
                      className="text-white text-sm border-white hover:border-b-4 py-2"
                      onClick={handleScrollToTop}
                    >
                      PRODUCTS
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/Business"}
                      className="text-white text-sm border-white hover:border-b-4 py-2"
                      onClick={handleScrollToTop}
                    >
                      BUSINESS
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/About"}
                      className="text-white text-sm border-white hover:border-b-4 py-2"
                      onClick={handleScrollToTop}
                    >
                      ABOUT
                    </Link>
                  </li>
                  {/* <li>
                  <a
                    href="#"
                    className="text-white text-sm border-white hover:border-b-4 py-2"
                  >
                    MEDIA
                  </a>
                </li> */}
                  <li>
                    <Link
                      to="/Career"
                      className="text-white text-sm border-white hover:border-b-4 py-2"
                      onClick={handleScrollToTop}
                    >
                      CAREERS
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/ContactUs"}
                      className="text-white text-sm border-white hover:border-b-4 py-2"
                      onClick={handleScrollToTop}
                    >
                      CONTACT US
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="flex lg:hidden relative justify-end self-end ">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-end justify-center rounded-md p-2.5 text-white ml-auto"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon
                    className="h-8 w-8 lg:h-10 lg:w-10 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>

            {/* Mobile menu toggle button */}
            <Dialog
              as="div"
              className="lg:hidden fixed inset-0 z-50"
              open={mobileMenuOpen}
              onClose={() => setMobileMenuOpen(false)}
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
              <div className="flex  h-screen">
                <Dialog.Panel className="z-50 w-full max-w-screen-md bg-gradient-to-r from-slate-950 to-[#144784] p-8 rounded-xl">
                  <div className="flex mt-8 items-center justify-between">
                    {" "}
                    <button
                      type="button"
                      className="-m-2.5 rounded-md p-2.5 text-gray-700"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon
                        className=" h-6 w-6 md:h-10 md:w-10"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                  {/* Mobile menu */}
                  {
                    <div className=" bg-gradient-to-r from-slate-950 to-[#144784]">
                      <ul className="flex flex-col space-y-4 p-4">
                        <li>
                          <Link
                            to="/"
                            className="text-white text-sm border-white hover:border-b-4 py-2"
                          >
                            HOME
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/Products"}
                            className="text-white text-sm border-white hover:border-b-4 py-2"
                            onClick={handleScrollToTop}
                          >
                            PRODUCTS
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/Business"}
                            className="text-white text-sm border-white hover:border-b-4 py-2"
                          >
                            BUSINESS
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/About"}
                            className="text-white text-sm border-white hover:border-b-4 py-2"
                          >
                            ABOUT
                          </Link>
                        </li>
                        {/* <li>
                    <a
                      href="#"
                      className="text-white text-sm border-white hover:border-b-4 py-2"
                    >
                      MEDIA
                    </a>
                  </li> */}
                        <li>
                          <Link
                            to={"/Career"}
                            className="text-white text-sm border-white hover:border-b-4 py-2"
                          >
                            CAREERS
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/ContactUs"}
                            className="text-white text-sm border-white hover:border-b-4 py-2"
                          >
                            CONTACT US
                          </Link>
                        </li>
                      </ul>
                    </div>
                  }
                </Dialog.Panel>
              </div>
            </Dialog>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
