import React from "react";
import Header from "../../Common/Header";
import Footer from "../../Common/Footer";
import ProductsHeroSection from "./ProductsHeroSection";
import ProductsGrid from "./ProductsGrid";
import FeaturedProducts from "./FeaturedProducts";

const Products = () => {
  return (
    <div>
      <Header />
      <ProductsHeroSection />
      <ProductsGrid />
      <FeaturedProducts />
      <Footer />
    </div>
  );
};

export default Products;
