import React, { useEffect, useState } from "react";
import ProductData from "../../../JSON/Products.json";
import { useParams } from "react-router-dom";

const EachProduct = () => {
  const { productId } = useParams();

  const [product, setProduct] = useState("");
  const [contentType, setContentType] = useState("description");

  useEffect(() => {
    console.log("Product ID:", productId);
    const selectedProduct = ProductData.products.find(
      (e) => e.id === parseInt(productId)
    );

    console.log("Selected Product:", selectedProduct);
    setProduct(selectedProduct);
  }, [productId]);

  console.log("Product State:", product);

  const handleContentChange = (contentType) => {
    setContentType(contentType);
  };

  return (
    <div className="2xl:px-72  xl:px-40 xl:py-16 lg:px-16 lg:py-10 md:p-10 p-5 w-full">
      <div className="w-full flex justify-center flex-col items-center">
        <div className="max-w-screen-2xl w-full">
          {product && (
            <div className="w-full h-full flex flex-col items-start md:px-10 px-4">
              <div className="flex w-full h-full  items-start md:flex-row flex-col-reverse">
                <div className="md:w-6/12 w-full  flex flex-col  ">
                  <h2
                    className="w-auto items-center xl:text-5xl lg:text-4xl md:text-3xl text-2xl  textblue font-bold wow fadeInLeftBig "
                    data-wow-delay=".1s"
                    data-wow-duration="2s"
                  >
                    {product.title}
                  </h2>
                  <hr
                    className="bgblue h-0.5 my-2 md:w-72 w-44 wow fadeInLeft"
                    data-wow-delay=".1s"
                    data-wow-duration="2s"
                  />
                  <ul className="py-4 4xl:text-lg xl:text-base md:text-sm text-xs">
                    <li>Part Number : {product.PartNumber} </li>
                    <li>Category : {product.category}</li>
                    <li>Model No : {product.ModelNo}</li>
                    <li>Brand : {product.Brand}</li>
                    <li>Weight : {product.Weight}</li>
                    <li>Color : {product.Color}</li>
                    <li>Origin : {product.Origin}</li>
                  </ul>
                  <button className="w-40 bgblue text-white p-2 font-medium 4xl:text-xl xl:text-lg md:text-base text-sm">
                    Enquire
                  </button>
                </div>
                <div className="md:w-6/12 w-12/12  my-4 flex justify-center ">
                  <div className="p-4   customshadowcard1">
                    <img
                      src={product.image}
                      className=" 2xl:w-80 2xl:h-80 xl:h-72 xl:w-72 md:h-60 md:w-60 h-60 w-full  hover:scale-110"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col 2xl:-mt-20 md:-mt-10">
                <div className="md:mt-10 mt-6 mb-5 flex w-full">
                  <button
                    className={`xl:w-40 w-36 md:p-2 p-1 font-medium mr-1 4xl:text-xl xl:text-lg md:text-base text-sm ${
                      contentType === "description"
                        ? "bgblue text-white border borderblues"
                        : "border borderblue "
                    }`}
                    onClick={() => handleContentChange("description")}
                  >
                    Description
                  </button>
                  <button
                    className={`xl:w-40 w-36  md:p-2 p-1 font-medium mr-1 4xl:text-xl xl:text-lg md:text-base text-sm ${
                      contentType === "details"
                        ? "bgblue text-white"
                        : "borderblue  border"
                    }`}
                    onClick={() => handleContentChange("details")}
                  >
                    Product Details
                  </button>
                  <button
                    className={`xl:w-40 w-36  md:p-2 p-1 font-medium mr-1 4xl:text-xl xl:text-lg md:text-base text-sm ${
                      contentType === "resources"
                        ? "bgblue text-white"
                        : "borderblue border"
                    }`}
                    onClick={() => handleContentChange("resources")}
                  >
                    Resources
                  </button>
                </div>

                <div>
                  {contentType === "description" && (
                    <ul className="Metropolis-Medium list-inside  list-disc 4xl:text-lg xl:text-base md:text-sm text-xs">
                      {" "}
                      {product.Description.map((description, index) => (
                        <li className="my-1">{description}</li>
                      ))}
                    </ul>
                  )}
                  {contentType === "details" && (
                    <ul className="Metropolis-Medium list-inside  list-disc 4xl:text-lg xl:text-base md:text-sm text-xs">
                      {" "}
                      {product.ProductDetails.map((details, index) => (
                        <li className="my-1">{details}</li>
                      ))}
                    </ul>
                  )}
                  {contentType === "resources" && (
                    <ul className="Metropolis-Medium list-inside  list-disc 4xl:text-lg xl:text-base md:text-sm text-xs">
                      {" "}
                      {product.Resources.map((resource, index) => (
                        <li className="my-1">{resource}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EachProduct;
