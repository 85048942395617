import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "../Components/Common/Loading";
import NotFound from "../Components/Common/NotFound";
import Products from "../Components/Pages/ProducstPage/Products";
import ProductDetails from "../Components/Pages/ProductDetails/ProductDetails";

const Home = lazy(() => import("../Components/Pages/HomePage/Home"));
const Career = lazy(() => import("../Components/Pages/CareerPage/Career"));
const About = lazy(() => import("../Components/Pages/AboutPage/About"));
const ContactUs = lazy(() =>
  import("../Components/Pages/ContactUsPage/ContactUs")
);
const CareerDetails = lazy(() =>
  import("../Components/Pages/CareerDetailsPage/CareerDetails")
);
const Business = lazy(() =>
  import("../Components/Pages/BusinessPage/Business")
);

const MainRoute = () => {
  return (
    <>
      {" "}
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Business" element={<Business />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/CareerDetails" element={<CareerDetails />} />

          <Route path="/Career" element={<Career />} />
          <Route path="/Products" element={<Products />} />
          <Route
            path="/ProductDetails/:productId"
            element={<ProductDetails />}
          />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default MainRoute;
