import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div>
      <div className=" bgblue h-dvh w-full flex flex-col items-center justify-center">
        <div className="text-white error ">404</div>
        <div className="text-white font-medium my-1 lg:text-lg text-xs">
          PAGE NOT FOUND
        </div>

        <Link
          to="/"
          className="text-white px-2 my-2 font-semibold lg:text-sm text-xs flex flex-col items-center "
        >
          <h5>BACK TO HOME </h5>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            height="30"
            width="30"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
