import React from 'react'

const Loading = () => {
  return (
    <>
    <div className='h-screen flex justify-center items-center text-[#144784] text-6xl Monsterrat-font '>
       Loading...
    </div>
    </>
  )
}

export default Loading
