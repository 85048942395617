import React from "react";
import Footer from "../../Common/Footer";
import ProductDetailsHeroSection from "./ProductDetailsHeroSection";
import Header from "../../Common/Header";
import EachProduct from "./EachProduct";
import BestSellers from "./BestSellers";

const ProductDetails = () => {
  return (
    <div>
      <Header />
      <ProductDetailsHeroSection />
      <EachProduct />
      <BestSellers />
      <Footer />
    </div>
  );
};

export default ProductDetails;
