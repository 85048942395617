import React, { useEffect, useState } from "react";

import Banner from "../../../Images/arc-welding-steel-construction-site.jpg";

const ProductDetailsHeroSection = () => {
  const [currentIndex, setCurrentSlideIndex] = useState(0);
  const [autoplayTimer, setAutoplayTimer] = useState(null);
  const autoplayInterval = 3000;
  const slides = [
    {
      url: Banner,
    },
    {
      url: Banner,
    },
    {
      url: Banner,
    },
    {
      url: Banner,
    },
  ];

  const preloadImages = () => {
    slides.forEach((slide) => {
      const img = new Image();
      img.src = slide.url;
    });
  };

  useEffect(() => {
    preloadImages();
    StartAutoplay();

    return () => {
      StopAutoPlay();
    };
  }, [currentIndex]);

  const StartAutoplay = () => {
    const timer = setInterval(() => {
      const nextIndex = (currentIndex + 1) % slides.length;
      setCurrentSlideIndex(nextIndex);
    }, autoplayInterval);
    setAutoplayTimer(timer);
  };

  const StopAutoPlay = () => {
    clearInterval(autoplayTimer);
  };

  const handleSliderClick = () => {
    StopAutoPlay();
  };
  return (
    <>
      <div className="h-min-screen  relative  overflow-hidden -mt-16 ">
        <div
          style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
          className="h-dvh bg-no-repeat  w-auto slider-container"
        ></div>
        <div className=" absolute top-0 left-0 w-full h-full bg-black bg-opacity-50	">
          {/* vertical line Bar  */}
          <div
            className=" absolute  w-2   md:right-32 right-16 top-1 wow  slideInDown"
            data-wow-delay=".1s"
            data-wow-duration="2s"
          >
            <svg
              className="w-6 h-50 text-white grid-end grid grid-rows-2"
              viewBox="0 0 33.94 358.8"
            >
              <line
                class="strokeCurrent stroke-white stroke-0.75"
                x1="22.39"
                x2="22.39"
                y2="354"
              />
              <line
                class="strokeCurrent stroke-white stroke-0.75"
                x1="13.39"
                y1="27.16"
                x2="13.39"
                y2="332"
              />
              <line
                class="strokeCurrent stroke-white stroke-0.75"
                x1="31.39"
                y1="27.16"
                x2="31.39"
                y2="329"
              />
            </svg>
            <span className=" grid-end grid grid-rows-2  text-white text-sm rotate-90 whitespace-nowrap uppercase">
              SCROLL DOWN
            </span>
            <svg
              className=" absolute top-96 w-6 h-60 grid-end grid grid-rows-2 text-white"
              viewBox="0 0 33.94 358.8"
            >
              <line
                className="strokeCurrent stroke-white stroke-0.75"
                x1="22.39"
                x2="22.39"
                y2="354"
              />

              <line
                className="strokeCurrent stroke-white stroke-0.75"
                x1="13.39"
                y1="27.16"
                x2="13.39"
                y2="332"
              />
              <line
                className="strokeCurrent stroke-white stroke-0.75"
                x1="31.39"
                y1="27.16"
                x2="31.39"
                y2="329"
              />
            </svg>
            <i className="absolute fa-solid fa-angle-down text-white pl-2 pt-80"></i>
          </div>
          {/* ----------social icons---------- */}
          <div className="absolute text-white xl:left-40 lg:left-30 md:left-16 left-6 top-[46%] ">
            <span class="inline-grid grid-rows-4 gap-2 ">
              <span>
                <i class="fa-brands fa-facebook"></i>
              </span>
              <span>
                <i class="fa-brands fa-instagram"></i>
              </span>
              <span>
                <i class="fa-brands fa-linkedin-in"></i>
              </span>
              <span>
                <i class="fa-brands fa-x-twitter"></i>
              </span>
            </span>
          </div>
          {/* --------------heading ------------- */}
          <div>
            <div>
              <h1
                className="absolute text-white xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-bold  top-[44%]  left-[17%] xl:w-[52%] w-[65%]  wow slideInLeft "
                data-wow-duration="1.2s"
              >
                Amplyfying your Capability with our range of products and
                service
              </h1>
            </div>
            <div className="absolute top-2/3 xl:left-[14.9rem] lg:left-44 md:left-32 left-20">
              {/* ----------read more btn --------------
            <div className=" absolute top-2/3 left-[14.9rem]"> */}
              <button
                className=" flex  text-white font-light  xl:text-xl lg:text-lg md:text-base text-sm  py-2 xl:px-4   border-slate-200  border-b-2  wow slideInLeft "
                data-wow-duration="1.2s"
              >
                Read More{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-5 mt-2 pl-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsHeroSection;
