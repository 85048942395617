import React, { useState } from "react";
import logo from "../../Images/logo.png";
import logo1 from "../../Images/logo1.png";
import facebook from "../../Images/facebook.png";
import instagram from "../../Images/instagram.png";
import linkedin from "../../Images/linkedin.png";
import twitter from "../../Images/twitter.png";

const Footer = () => {
  const [isHovered, setHovered] = useState(false);
  return (
    <>
      <footer className="h-auto  relative w-full    ">
        <div className=" xl:pt-10 lg:pt-8 md:pt-6 pt-4 xl:px-24 px-6 h-auto   flex flex-col justify-between  overflow-hidden mx-auto relative bg-cover bg-no-repeat  bgblue ">
          <div className="w-full flex justify-center flex-col items-center">
            <div className="max-w-screen-2xl w-full">
              <div className="flex text-white justify-between ">
                <div className=" text-white xl:text-3xl lg:text-2xl md:text-xl text-lg font-semibold ">
                  Unacceptional Brilliance
                </div>
                <div className="xl:text-sm text-xs">
                  <p className="border-b border-white">
                    Aramco VendorID : 1052538
                  </p>
                  <p>SABIC VendorID : 507578</p>
                </div>
              </div>
              <div className="Monsterrat-font text-stroke absolute 4xl:left-96 2xl:right-36 xl:right-10 lg:right-2 md:right-2  flex items-center justify-center md:top-24 lg:top-28 top-48  font-black  uppercase xl:text-9xl lg:text-8xl md:text-7xl text-4xl md:leading-none  z-1 ">
                <text>
                  Commercial
                  <br />
                  Central
                  <br />
                  Systems
                </text>
              </div>

              <div className=" p-4">
                <div className="  items-center">
                  {/* grid section  */}
                  <div className="grid md:grid-cols-4 sm:grid-cols-1 lg:pt-8 md:pt-6   z-2 relative">
                    <div className="">
                      <div className="uppercase pb-2 md:pb-0 text-white xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-bold tracking-wide pt-6">
                        <div
                          className=" xl:w-64   lg:w-52 md:w-36 w-32"
                          onMouseEnter={() => setHovered(true)}
                          onMouseLeave={() => setHovered(false)}
                        >
                          {isHovered ? (
                            <img src={logo1} alt="CCSElogo1" />
                          ) : (
                            <img src={logo} alt="CCSElogo" />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:space-y-3  xl:mx-12 lg:mx-8 mx-4 my-1 z-2 relative">
                      <h3 className="border-b text-white w-1/2 xl:text-lg lg:text-base text-sm  border-white  hover:border-b-4 py-2 uppercase font-lighter ">
                        Businesses
                      </h3>
                      <ol className="xl:text-sm text-xs  space-y-1 text-white list-none list-inside	 ">
                        <li>
                          <a href=""> Material Supply Management</a>
                        </li>
                        <li>
                          <a href="">Offshore Marine Oilfield Supply </a>
                        </li>
                        <li>
                          <a href="">Industrial Material Supply</a>
                        </li>
                        <li>
                          <a href=""> Safety Tools & Supplies</a>
                        </li>
                      </ol>
                    </div>
                    <div className="md:space-y-3  xl:mx-12 lg:mx-8 mx-4 my-1 z-2 relative">
                      <h3 className="border-b text-white xl:text-lg lg:text-base text-sm w-1/2 border-white  hover:border-b-4 py-2 uppercase font-lighter">
                        Addresses
                      </h3>
                      <ul className="xl:text-sm text-xs  space-y-1 text-white">
                        <li>
                          <a href="">Office No.102,</a>
                        </li>
                        <li>
                          <a href="">Saudi Fransi Bank Building,</a>
                        </li>
                        <li>
                          <a href="">Omar Bin Abdulaziz St.,</a>
                        </li>
                        <li>
                          <a href="">Saihat 32412,</a>
                        </li>
                        <li>
                          <a href="">Saudi Arabia</a>
                        </li>
                      </ul>
                    </div>
                    <div className="md:space-y-2 xl:mx-12 lg:mx-8 mx-4 my-1 z-2 relative">
                      <h3 className=" border-b text-white xl:text-lg lg:text-base text-sm w-2/5 border-white  hover:border-b-4 py-2 uppercase font-lighter">
                        Company
                      </h3>
                      <ol className="xl:text-sm text-xs space-y-1 text-white list-none list-inside	">
                        <li>
                          <a href="">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="">Cookie Policy</a>
                        </li>
                        <li>
                          <a href="">Terms & Conditions</a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="grid md:grid-cols-4 sm:grid-cols-1 lg:pt-8 md:pt-6 pt-2  z-2 relative">
                  <div></div>
                  <div className="md:space-y-3  xl:mx-12 lg:mx-8 mx-4 my-1 z-2 relative">
                    <h3 className="border-b text-white xl:text-lg lg:text-base text-sm w-4/5 border-white  hover:border-b-4 py-2 uppercase font-lighter">
                      CONNECT US
                    </h3>
                    <ul className="xl:text-sm text-xs w-4/5 space-y-1 text-white text-right">
                      <li>
                        <a href="">+966 13812956</a>
                      </li>
                      <li>
                        <a href="">www.ccsetrading.com</a>
                      </li>
                    </ul>
                  </div>
                  <div></div>
                  {/* social media icon  */}
                  <div className="md:space-y-2 xl:mx-12 lg:mx-8 mx-4 my-1 z-2 relative">
                    <span class=" xl:gap-4 flex xl:text-3xl lg:text-2xl text-xl gap-3 ">
                      <span>
                        <img
                          src={facebook}
                          alt="Facebook"
                          className="xl:w-10 lg:w-8 w-6 "
                        />
                      </span>
                      <span>
                        <img
                          src={instagram}
                          alt="Instagram"
                          className="xl:w-10 lg:w-8 w-6 "
                        />
                      </span>
                      <span>
                        <img
                          src={linkedin}
                          alt="Linkedin"
                          className="xl:w-10 lg:w-8 w-6 "
                        />
                      </span>
                      <span>
                        <img
                          src={twitter}
                          alt="Twitter"
                          className="xl:w-10 lg:w-8 w-6 "
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              {/* rights reserved  */}

              <div className="text-center  lg:p-10 md:p-6 p-4 ">
                <h4 className="text-white xl:text-sm text-xs ml-auto tracking-wide">
                  <i
                    className="far fa-copyright"
                    style={{ color: "#f7f9fd" }}
                  />{" "}
                  CCSE | All Rights Reserved 2024
                </h4>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
