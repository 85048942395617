import React,{useEffect} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainRoute from "./Routes/MainRoute";
import $ from "jquery";
import "./App.css";
import "./index.css";

import "./App.css";

import WOW from "wow.js";
import "animsition";
import "animsition/dist/css/animsition.css";
import "../src/Components/css/animate.min.css";
import "../src/Components/Pages/JS/Script";



const App = () => {
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
      });
    });
    new WOW().init();
  }, []);
  return (
    <>
      
      <Router>
        <MainRoute />
      </Router>
      
    </>
  );
};

export default App;
