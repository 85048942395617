import React, { useEffect, useState } from "react";
import brandsData from "../../../JSON/Brands.json";
import categoriesData from "../../../JSON/Category.json";
import colorData from "../../../JSON/Color.json";
import priceData from "../../../JSON/Price.json";
import productsData from "../../../JSON/Products.json";
import { Link } from "react-router-dom";

const ProductsGrid = () => {
  const [showAllColors, setShowAllColors] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [showAllBrands, setShowAllBrands] = useState(false);
  const [showAllPrices, setShowAllPrices] = useState(false);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    setFilteredProducts(productsData.products);
  }, []);

  const toggleShowAllColors = () => {
    setShowAllColors(!showAllColors);
  };

  const toggleShowAllCategories = () => {
    setShowAllCategories(!showAllCategories);
  };

  const toggleShowAllBrands = () => {
    setShowAllBrands(!showAllBrands);
  };

  const toggleShowAllPrices = () => {
    setShowAllPrices(!showAllPrices);
  };

  const handleColorChange = (e) => {
    const colorId = e.target.value;
    setSelectedColors((prevSelectedColors) =>
      prevSelectedColors.includes(colorId)
        ? prevSelectedColors.filter((color) => color !== colorId)
        : [...prevSelectedColors, colorId]
    );
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategories((prevSelectedCategories) =>
      prevSelectedCategories.includes(categoryId)
        ? prevSelectedCategories.filter((category) => category !== categoryId)
        : [...prevSelectedCategories, categoryId]
    );
  };

  const handleBrandChange = (e) => {
    const brandId = e.target.value;
    setSelectedBrands((prevSelectedBrands) =>
      prevSelectedBrands.includes(brandId)
        ? prevSelectedBrands.filter((brand) => brand !== brandId)
        : [...prevSelectedBrands, brandId]
    );
  };

  const handlePriceRangeChange = (e) => {
    const newPriceRange = e.target.value.split(",").map(Number);
    setPriceRange(newPriceRange);
  };

  const applyFilters = () => {
    let filteredProducts = [...productsData.products];
    // console.log(priceRange, "Prices");
    if (
      selectedBrands.length > 0 ||
      selectedCategories.length > 0 ||
      priceRange.length > 0 ||
      selectedColors.length > 0
    ) {
      if (selectedBrands.length > 0) {
        const selectedBrandNames = selectedBrands.map(
          (brandId) =>
            brandsData.brands.find((brand) => brand.id === brandId)?.name
        );

        filteredProducts = filteredProducts.filter((product) =>
          selectedBrandNames.includes(product.Brand)
        );
      }

      if (selectedCategories.length > 0) {
        const selectedCategoryNames = selectedCategories.map(
          (categoryId) =>
            categoriesData.categories.find(
              (category) => category.id === categoryId
            )?.name
        );

        filteredProducts = filteredProducts.filter((product) =>
          selectedCategoryNames.includes(product.category)
        );
      }

      if (selectedColors.length > 0) {
        const selectedColorNames = selectedColors.map(
          (colorId) =>
            colorData.colors.find((color) => color.id === colorId)?.name
        );

        filteredProducts = filteredProducts.filter((product) =>
          selectedColorNames.includes(product.Color)
        );
      }

      if (priceRange.length > 0) {
        filteredProducts = filteredProducts.filter(
          (product) =>
            product.PRICE >= priceRange[0] && product.PRICE <= priceRange[1]
        );
      }
      console.log("Filtered products:", filteredProducts);

      setFilteredProducts(filteredProducts);
    } else {
      // console.log(priceRange);
      console.log("Filtered products:", filteredProducts);
      setFilteredProducts(filteredProducts);
    }
  };

  const renderProducts = () => {
    if (filteredProducts.length === 0) {
      return <p>No products found</p>;
    }

    return filteredProducts.map((item, index) => (
      <Link
        to={`/ProductDetails/${item.id}`}
        key={index}
        onClick={handleScrollToTop}
        className=""
      >
        <div className="lg:w-72 md:w-56  w-full m-3" key={index}>
          <div className="2xl:h-60 h-44 flex flex-col items-center justify-center 2xl:w-72 lg:w-60 md:w-56 w-60 customshadowcard">
            <img
              className="md:h-40 h-28  items-center"
              src={item.image}
              alt={item.title}
            />
          </div>
          <h2 className="font-bold my-2 uppercase xl:text-lg lg:text-lg md:text-base text-sm">
            {item.title}
          </h2>
          <div className="flex 2xl:w-72 md:w-56 w-10/12 justify-between">
            <div>
              <p className="uppercase text-[0.7rem] font-medium text-gray-500">
                Category
              </p>
              <p className="uppercase text-xs font-bold">{item.category}</p>
            </div>
            <div className="flex items-center gap-1">
              <p className="uppercase text-xs font-bold">Price</p>
              <p className="uppercase md:text-base text-sm font-bold">
                ${item.PRICE}
              </p>
            </div>
          </div>
        </div>
      </Link>
    ));
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  const colorSliceEnd = showAllColors ? colorData.colors.length : 2;
  const categorySliceEnd = showAllCategories
    ? categoriesData.categories.length
    : 2;
  const brandSliceEnd = showAllBrands ? brandsData.brands.length : 2;

  return (
    <>
      <div className="xl:px-24 xl:py-16 lg:px-16 lg:py-10 md:p-12 p-5 w-full">
        <div className="w-full flex justify-center flex-col items-center">
          <div className="max-w-screen-2xl w-full">
            <div className="w-full flex justify-around ">
              <div className="md:w-9/12 w-full">
                <h2 className="w-fit items-center xl:text-5xl lg:text-4xl md:text-3xl text-2xl textblue font-bold">
                  Products
                </h2>
                <hr className="bgblue h-0.5 my-2 md:w-96 w-28" />
                <h4 className="w-fit xl:text-2xl lg:text-xl md:text-lg text-base font-medium md:mb-6 mb-4">
                  Your Partner in Industrial Advancement
                </h4>
                <div className="flex flex-wrap md:justify-start justify-center items-center w-full">
                  {renderProducts()}
                </div>
              </div>
              <div className="md:w-3/12 md:flex hidden items-start flex-col">
                <div className="flex w-full flex-col justify-end my-5">
                  <div className="flex w-full flex-row justify-end">
                    <h2 className="xl:text-3xl lg:text-2xl md:text-xl text-lg font-medium">
                      Filters
                    </h2>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="#144784"
                      className="w-6 h-6 mt-2 pl-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                      />
                    </svg>
                  </div>
                  <hr className="bgblue h-0.5 my-1" />
                </div>
                <div className="w-full">
                  <div className="py-4 w-full">
                    <h2 className="xl:text-lg lg:text-base md:text-sm text-sm border-b border-black mb-2">
                      Categories
                    </h2>
                    {categoriesData.categories
                      .slice(0, categorySliceEnd)
                      .map((category, index) => (
                        <div key={index}>
                          <input
                            type="checkbox"
                            id={`category_${category.id}`}
                            name={`category_${category.id}`}
                            value={category.id}
                            className="mx-1"
                            onChange={handleCategoryChange}
                          />
                          <label
                            htmlFor={`category_${category.id}`}
                            className="xl:text-base lg:text-sm text-xs font-medium"
                          >
                            {category.name}
                          </label>{" "}
                        </div>
                      ))}
                    {categoriesData.categories.length > 2 && (
                      <button
                        onClick={toggleShowAllCategories}
                        className="text-right w-full xl:text-sm text-xs font-medium"
                      >
                        {showAllCategories ? "View Less" : "View All"}
                      </button>
                    )}
                  </div>
                  <div className="py-4">
                    <h2 className="xl:text-lg lg:text-base md:text-sm text-sm border-b border-black mb-2">
                      Brands
                    </h2>
                    {brandsData.brands
                      .slice(0, brandSliceEnd)
                      .map((brand, index) => (
                        <div key={index}>
                          <input
                            type="checkbox"
                            id={brand.id}
                            name={brand.name}
                            value={brand.id}
                            className="mx-1"
                            onChange={handleBrandChange}
                          />
                          <label
                            htmlFor={brand.id}
                            className="xl:text-base lg:text-sm text-xs font-medium"
                          >
                            {brand.name}
                          </label>{" "}
                        </div>
                      ))}
                    {brandsData.brands.length > 2 && (
                      <button
                        onClick={toggleShowAllBrands}
                        className="text-right w-full xl:text-sm text-xs font-medium"
                      >
                        {showAllBrands ? "View Less" : "View All"}
                      </button>
                    )}
                  </div>
                  <div className="py-4 w-full">
                    <h2 className="xl:text-lg lg:text-base md:text-sm text-sm border-b border-black mb-2">
                      Price Range
                    </h2>
                    <div className="range-slider">
                      <input
                        name="range-1"
                        value={priceRange[0]}
                        min="0"
                        max="1000"
                        step="1"
                        type="range"
                        onChange={(e) => {
                          const newMin = parseInt(e.target.value);
                          setPriceRange([newMin, priceRange[1]]);
                        }}
                      />
                      <input
                        name="range-2"
                        value={priceRange[1]}
                        min="0"
                        max="1000"
                        step="1"
                        type="range"
                        onChange={(e) => {
                          const newMax = parseInt(e.target.value);
                          setPriceRange([priceRange[0], newMax]);
                        }}
                      />
                      <div className="track track--full"></div>
                      <div
                        className="track track--included"
                        style={{ width: `${(priceRange[1] / 1000) * 100}%` }}
                      ></div>
                      <div
                        className="track track--excluded"
                        style={{ width: `${(priceRange[0] / 1000) * 100}%` }}
                      ></div>
                      <p className="xl:text-base lg:text-sm text-xs font-medium">
                        Min: ${priceRange[0]} - Max: ${priceRange[1]}
                      </p>
                    </div>
                  </div>

                  <div className="py-4">
                    <h2 className="xl:text-lg lg:text-base md:text-sm text-sm border-b border-black mb-2">
                      Color
                    </h2>
                    {colorData.colors
                      .slice(0, colorSliceEnd)
                      .map((color, index) => (
                        <div key={index}>
                          <input
                            type="checkbox"
                            id={`color_${color.id}`}
                            name={`color_${color.id}`}
                            value={color.id}
                            className="mx-1"
                            onChange={handleColorChange}
                          />
                          <label
                            htmlFor={`color_${color.id}`}
                            className="xl:text-base lg:text-sm text-xs font-medium"
                          >
                            {color.name}
                          </label>{" "}
                        </div>
                      ))}
                    {colorData.colors.length > 2 && (
                      <button
                        onClick={toggleShowAllColors}
                        className="text-right w-full xl:text-sm text-xs font-medium "
                      >
                        {showAllColors ? "View Less" : "View All"}
                      </button>
                    )}
                  </div>
                  <button
                    onClick={applyFilters}
                    className="xl:text-base lg:text-sm text-xs font-medium bgblue p-2 text-white w-full my-2"
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsGrid;
